<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="执行车辆编号" prop="vehicleId">
        <a-select
          v-model="form.vehicleId"
          placeholder="请选择"
        >
          <a-select-option v-for="(d, index) in vehiclesOptions" :key="index" :value="d.id">
            {{ d.vehicleId }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="任务名称" prop="taskName" >
        <a-input v-model="form.taskName" placeholder="请输入任务名称" />
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="taskStartTime" >
        <a-date-picker style="width: 100%" v-model="form.taskStartTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="预计结束时间" prop="taskExpectTime" >
        <a-date-picker style="width: 100%" v-model="form.taskExpectTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="选择路线" prop="taskRouteId">
        <a-select
          v-model="form.taskRouteId"
          placeholder="请选择"
        >
          <a-select-option v-for="(d, index) in routePlanningsOptions" :key="index" :value="d.id">
            {{ d.routeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="任务备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入任务备注" />
      </a-form-model-item>
      <a-form-model-item label="任务冲突" prop="taskConflict" >
        <a-input v-model="form.taskConflict" placeholder="请输入任务冲突" />
      </a-form-model-item>
      <a-form-model-item label="任务状态">
        <a-input v-model="form.taskState" placeholder="请输入任务状态" />
      </a-form-model-item>
      <a-form-model-item label="任务进度">
        <a-input v-model="form.taskProgress" placeholder="请输入任务进度" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleTask, addVehicleTask, updateVehicleTask } from '@/api/iot/vehicleTask'

export default {
  name: 'CreateForm',
  props: {

  },
  components: {
  },
  data () {
    return {
      // 路线选项
      routePlanningsOptions: [],
      // 车辆选项
      vehiclesOptions: [],
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        taskName: null,
        taskStartTime: null,
        taskExpectTime: null,
        taskRouteId: null,
        remark: null,
        taskConflict: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        taskName: [
          { required: true, message: '任务名称不能为空', trigger: 'blur' }
        ],
        taskStartTime: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ],
        taskRouteId: [
          { required: true, message: '路线不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        taskName: null,
        taskStartTime: null,
        taskExpectTime: null,
        taskRouteId: null,
        remark: null,
        taskConflict: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getVehicleTask(0).then(response => {
        this.routePlanningsOptions = response.routePlannings
        this.vehiclesOptions = response.vehicles
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleTask(id).then(response => {
        this.form = response.data
        this.routePlanningsOptions = response.routePlannings
        this.vehiclesOptions = response.vehicles
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleTask(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleTask(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
