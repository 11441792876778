var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "执行车辆编号", prop: "vehicleId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.vehicleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleId", $$v)
                    },
                    expression: "form.vehicleId",
                  },
                },
                _vm._l(_vm.vehiclesOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.id } },
                    [_vm._v(" " + _vm._s(d.vehicleId) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务名称", prop: "taskName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务名称" },
                model: {
                  value: _vm.form.taskName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskName", $$v)
                  },
                  expression: "form.taskName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "开始时间", prop: "taskStartTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.taskStartTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskStartTime", $$v)
                  },
                  expression: "form.taskStartTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "预计结束时间", prop: "taskExpectTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.taskExpectTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskExpectTime", $$v)
                  },
                  expression: "form.taskExpectTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "选择路线", prop: "taskRouteId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.taskRouteId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "taskRouteId", $$v)
                    },
                    expression: "form.taskRouteId",
                  },
                },
                _vm._l(_vm.routePlanningsOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.id } },
                    [_vm._v(" " + _vm._s(d.routeName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务冲突", prop: "taskConflict" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务冲突" },
                model: {
                  value: _vm.form.taskConflict,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskConflict", $$v)
                  },
                  expression: "form.taskConflict",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务状态" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务状态" },
                model: {
                  value: _vm.form.taskState,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskState", $$v)
                  },
                  expression: "form.taskState",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务进度" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务进度" },
                model: {
                  value: _vm.form.taskProgress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskProgress", $$v)
                  },
                  expression: "form.taskProgress",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }